import { FileExcelTwoTone } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Select,
  SelectProps,
  Space,
  Typography,
  Cascader
} from "antd";
import { useContext, useEffect, useState } from "react";
import { DataType } from "../types";
import { AppContext } from "../../../../App";
import {
  generateActiveScorecardsReport,
  generateAddOnQueueReport,
  generateCallDiariesReport,
  generateCallsCompletedReport,
  generateCustomerInteractionsReport,
  generateFormalTrainingReport,
  generateGeneralDelegatesReport,
  generateGeneralOutletsReport,
  generateKnowledgeTransferReport,
  generateKnowledgeTransferSummarisedReport,
  generateOutletResearchReport,
  generateOutletResearchSummarisedReport,
  generatePOSReport,
  generateProductsReport,
  generateRedFlagReport,
  generateScorecardsAnswersReport,
  generateScorecardsReport,
  generateSKUReport,
  generateStocksReport,
  generateStrategicAuditReport,
  generateStrategicAuditSummarisedReport,
  generateSurveyAnswersReports,
  generateSurveyReports,
  generateTicketsReport,
  generateTimeSheetsReports,
  generateUserListReport,
  getPhotoAuditsReport,
  generateAxonifyReports
} from "./reports/red-flag-reports";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";
import xlsx from "json-as-xlsx";
import useApiPost from "../../../../hooks/useApiPost";
import { getAuth } from "firebase/auth";

enum ReportTypes {
  RED_FLAGS = "Red flags",
  SKU = "Stock Pricing",
  POS = "POS",
  STOCKS = "Stock on Hand",
  CALLS_COMPLETED = "Visits Completed",
  CALL_DIARIES = "Planned Call Diaries",
  KNOWLEDGE_TRANSFERS = "Knowledge transfers Answers",
  KNOWLEDGE_TRANSFERS_SUMMARISED = "Knowledge transfers Summarised",
  GENERAL_OUTLETS = "Active Calling Outlets",
  OUTLETS_QUEUE = "Add-On Queue",
  DELEGATES = "Active Delegates List",
  USERS_LIST = "User List",
  SURVEYS_SUMMARISED = "Surveys Summarised",
  SURVEYS_ANSWERS = "Surveys Answers",
  SCORECARDS = "Scorecards",
  CUSTOMER_INTERACTIONS = "Customer Interactions",
  SCORECARD_ANSWERS = "Scorecard Answers",
  OUTLET_RESEARCH = "Outlet Research Answers",
  OUTLET_RESEARCH_SUMMARISED = "Outlet Research Summarised",
  STRATEGIC_AUDIT = "Strategic Audit Answers",
  STRATEGIC_AUDIT_SUMMARISED = "Strategic Audit Summarised",
  ACTIVE_SCORECARDS_AUDITS = "Active Scorecards & Audits",
  FORMAL_TRAINING = "Formal Training Booking & Attendance",
  PRODUCTS = "Products",
  TICKETS = "Tickets",
  PHOTO_AUDITS = "Photo Audits",
  TIMESHEETS_REPORT = "Timesheets Report",
  AXONIFY_REPORT = "Axonify"
  // SURVEYS = "Surveys",
  // QUESTIONNAIRES = "Questionnaires",
}

const reportsMapper = {
  [ReportTypes.RED_FLAGS]: generateRedFlagReport,
  [ReportTypes.SKU]: generateSKUReport,
  [ReportTypes.POS]: generatePOSReport,
  [ReportTypes.STOCKS]: generateStocksReport,
  [ReportTypes.CALLS_COMPLETED]: generateCallsCompletedReport,
  [ReportTypes.CALL_DIARIES]: generateCallDiariesReport,
  [ReportTypes.KNOWLEDGE_TRANSFERS]: generateKnowledgeTransferReport,
  [ReportTypes.KNOWLEDGE_TRANSFERS_SUMMARISED]:
    generateKnowledgeTransferSummarisedReport,
  [ReportTypes.GENERAL_OUTLETS]: generateGeneralOutletsReport,
  [ReportTypes.OUTLETS_QUEUE]: generateAddOnQueueReport,
  [ReportTypes.DELEGATES]: generateGeneralDelegatesReport,
  [ReportTypes.USERS_LIST]: generateUserListReport,
  [ReportTypes.SURVEYS_SUMMARISED]: generateSurveyReports,
  [ReportTypes.SURVEYS_ANSWERS]: generateSurveyAnswersReports,
  [ReportTypes.SCORECARDS]: generateScorecardsReport,
  [ReportTypes.CUSTOMER_INTERACTIONS]: generateCustomerInteractionsReport,
  [ReportTypes.SCORECARD_ANSWERS]: generateScorecardsAnswersReport,
  [ReportTypes.OUTLET_RESEARCH]: generateOutletResearchReport,
  [ReportTypes.OUTLET_RESEARCH_SUMMARISED]:
    generateOutletResearchSummarisedReport,
  [ReportTypes.STRATEGIC_AUDIT]: generateStrategicAuditReport,
  [ReportTypes.STRATEGIC_AUDIT_SUMMARISED]:
    generateStrategicAuditSummarisedReport,
  [ReportTypes.ACTIVE_SCORECARDS_AUDITS]: generateActiveScorecardsReport,
  [ReportTypes.FORMAL_TRAINING]: generateFormalTrainingReport,
  [ReportTypes.PRODUCTS]: generateProductsReport,
  [ReportTypes.TICKETS]: generateTicketsReport,
  [ReportTypes.PHOTO_AUDITS]: getPhotoAuditsReport,
  [ReportTypes.TIMESHEETS_REPORT]: generateTimeSheetsReports,
  [ReportTypes.AXONIFY_REPORT]: generateAxonifyReports
};




const saveToCsv = (
  data: any[],
  title: string,
  headers: string[],
  showAlert = true
) => {
  // console.log("data", data);
  const csvExporter = new ExportToCsv({
    useBom: true,
    filename: title,
    showLabels: true,
    headers,
  });
  if (data.length === 0 && showAlert) {
    return alert("No data to export");
  }
  csvExporter.generateCsv(data);
};

interface XLSXProps {
  content: any[];
  title: string;
  columns: {
    label: string;
    value: string;
  }[];
}

const saveToXlsx = ({ content, title, columns }: XLSXProps) => {
  xlsx(
    [
      {
        columns,
        content,
      },
    ],
    {
      fileName: title,
      extraLength: 3,
    }
  );
};

const getHeaders = (title: string) => {
  switch (title) {
    case ReportTypes.POS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "Outlet",
        "Call Type",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Type",
        "Product",
        "Completed",
        "POS Item Count",
        "Compliance",
      ];
    case ReportTypes.RED_FLAGS:
      return [
        "Created At",
        "Completed At",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Outlet Type",
        "User Alias",
        "User Email",
        "Classification",
        "Category",
        "Subcategory",
        "Status",
        "Detail",
        "Photo",
        "Days Open",
      ];
    case ReportTypes.SKU:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Call Type",
        "Outlet Type",
        "User",
        "Product EAN",
        "Product",
        "Brief Price",
        "In Store Price",
        "Price Difference",
      ];
    case ReportTypes.STOCKS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Call Type",
        "Outlet Type",
        "User",
        "Product ID",
        "Product",
        "MSH",
        "SOH",
        "Variance",
        "Variance Indicator",
      ];
    case ReportTypes.GENERAL_OUTLETS:
      return [
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Outlet State",
        "Outlet Type",
        "Outlet Street Number ID",
        "Outlet Street Address",
        "Mall",
        "Outlet City",
        "Outlet Grading",
        "Outlet Latitude",
        "Outlet Longitude",
      ];
    case ReportTypes.OUTLETS_QUEUE:
      return [
        "Submitted At",
        "Store ID",
        "Outlet",
        "Outlet Street Number ID",
        "Street Number",
        "Shop Number ID",
        "Outlet Street Address",
        "Telephone Number",
        "Postal Code",
        "Mall",
        "Outlet Latitude",
        "Outlet Longitude",
        "Chain",
      ];
    case ReportTypes.DELEGATES:
      return [
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "Outlet Type",
        "Delegate Name",
        "Delegate ID Number",
        "Gender",
        "Disabled",
        "Email",
        "Mobile Number",
        "Delegate Role",
        "Is Active",
      ];
    case ReportTypes.CALLS_COMPLETED:
      return [
        "Outlet Visit",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "Outlet",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Type",
        "Call Type",
        "User Alias",
        "Check in time",
        "Check out time",
        "Visit Duration",
      ];
    case ReportTypes.USERS_LIST:
      return [
        "User ID",
        "User Alias",
        "User Email",
        "User Mobile Number",
        "User Role",
        "User State",
      ];
    case ReportTypes.CALL_DIARIES:
      return [
        "Planned Call Date",
        "User Alias",
        "Region",
        "County",
        "Channel",
        "Chain",
        "Outlet",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Type",
        "User Role",
        "Call Type",
        "Call Status",
        "Rejection Comment",
      ];
    case ReportTypes.KNOWLEDGE_TRANSFERS_SUMMARISED:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "User Alias",
        "User Role",
        "Call Type",
        "Training Module",
        // "Delegate ID",
        "Delegate Name",
        "Delegate Role",
        "Score",
        "Max Score",
        "Percentage",
        "Interventions",
      ];
    case ReportTypes.KNOWLEDGE_TRANSFERS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "Outlet Client Code",
        "Dealer ID",
        "Outlet ID",
        "Outlet Name",
        "User Alias",
        "User Role",
        "Call Type",
        "Training Module",
        "Delegate Name",
        "Delegate Surname",
        "Delegate Role",
        "Question",
        "Answer",
        "Points",
      ];

    case ReportTypes.STRATEGIC_AUDIT:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Cluster",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "User Alias",
        "Scorecard Name",
        "Question order",
        "Question",
        "Answer",
        "Score",
      ];
    case ReportTypes.STRATEGIC_AUDIT_SUMMARISED:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Cluster",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "User Alias",
        "Scorecard Name",
        "Score",
      ];
    case ReportTypes.SCORECARDS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "User Name",
        "User Role",
        "Scorecard Name (Task Name)",
        "Call Type",
        "Scorecard ID",
        "Max Score",
        "Score",
        "Percentage",
      ];
    case ReportTypes.CUSTOMER_INTERACTIONS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "Outlet Type",
        "User Name",
        "Type",
        "Product ID",
        "Description",
        "Is interested",
        "Not interested Reason",

        // "Barcode",
        // "Type",
        // "Region",
        // "County",
        // "Cluster",
        // "Chain",
        // "Outlet",
        // "Outlet Type",
        // "User",
      ];
    case ReportTypes.SCORECARD_ANSWERS:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "Outlet Type",
        "User Name",
        "User Role",
        "Scorecard Name (Task Name)",
        "Scorecard Question",
        "Scorecard Answer",
        "Score",
        "Max Score",
      ];
    case ReportTypes.OUTLET_RESEARCH:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Call Type",
        "User Name",
        "Role",
        "Questionnaire Name",
        "Question",
        "Answer",
      ];
    case ReportTypes.OUTLET_RESEARCH_SUMMARISED:
      return [
        "Visit ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet",
        "Call Type",
        "User Name",
        "Role",
        "Questionnaire Name",
      ];
    case ReportTypes.SURVEYS_ANSWERS: {
      return [
        "Visit ID",
        "Survey ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "Outlet Type",
        "Call Type",
        "Survey Name",
        "Question Number",
        "Question",
        "Answer",
      ];
    }
    case ReportTypes.SURVEYS_SUMMARISED: {
      return [
        "Visit ID",
        "Survey ID",
        "Date",
        "Region",
        "County",
        "Channel",
        "Chain",
        "L-Account",
        "Dealer ID",
        "Store ID",
        "Outlet Name",
        "Outlet Type",
        "Call Type",
        "Survey Name",
      ];
    }
    default:
      return [];
  }
};

const ReportsPage = () => {
  const auth = getAuth();
  const isSecretVisible =
    auth.currentUser?.email === "michelle.vanzyl@publicis.co.za" ||
    "demare.osmani@gmail.com" ||
    "rc20@publicis.co.za";

  const [form] = Form.useForm();
  const { projectId } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<ReportTypes[]>([]);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);
  const { request, setError } = useApiPost();
  const [chains, setChains] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const [tempStartDate, setTempStartDate] = useState<any>(undefined);
  const [tempEndDate, setTempEndDate] = useState<any>(undefined);

  const options: SelectProps["options"] = [];

  Object.entries(reportsMapper)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .filter((e) => isSecretVisible || e[0] !== ReportTypes.TICKETS)
    .map((e, index) => {
      options.push({
        label: e[0],
        value: e[0],
      });
    });

  const handleChange = (value: ReportTypes[]) => {
    const selectedValue = Array.isArray(value) ? value : [value];
    setSelected(selectedValue);
  };


  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const regionsData = await request(`/office/regions`, "POST", {
          projectId,
        });

        const chainData = await request(`/office/chain`, "POST", {
          projectId,
        });

        setChains(chainData.data);
        setTreeData(regionsData?.data?.regions);

      } catch (error) {
        console.error('Error fetching options:', error);
      }
    }
    fetchOptions();
  }, [])

  const displayRender = (labels: string[]) => labels[labels.length - 1];

  const generateReports = async (type: "CSV" | "XLSX") => {
    try {
      setIsLoading(true);
      console.log({ selected, startDate, endDate });
      const beginningOfDay = moment(startDate).startOf("day");
      const endOfDay = moment(endDate).endOf("day");
      
     await Promise.all(
      selected.map(async (e) => {
          let res = [];
          res = await reportsMapper[e](
            projectId,
            beginningOfDay,
            endOfDay,
            request
          );
  
          if (type === "XLSX") {
             console.log({ res });
            saveToXlsx(res);
          } else {
            console.log({ res });
            saveToCsv(res, e, getHeaders(e));
          }

       })
    );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const isSubmitEnabled = selected.length > 0 && !isLoading; // && startDate && endDate;

  const isRedFlagsSelected =
    selected.length === 1 && selected.includes(ReportTypes.RED_FLAGS);


  let date = new Date(tempStartDate)
  const future = new Date(date.setDate(date.getDate() + 31))

  return (
    <>
      <Typography.Title>Reports</Typography.Title>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Form.Item label="Start date">
          <DatePicker
            allowClear={false}
            name="startDate"
            //onChange={(date, dateString) => setStartDate(moment(dateString))}
            onChange={(e, dateString) => {
            setTempStartDate(e);
            setStartDate(moment(dateString))
            setTempEndDate(null);
            }}
            onKeyDown={(e) => {
              e.preventDefault()
              return false
            }}
            value={tempStartDate}
          />
        </Form.Item>
        <Form.Item
          label="End date"
          rules={[
            {
              message: "Please select a form end",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start = new Date(getFieldValue("startDate"));
                const end = new Date(value);

                if (start > end) {
                  return Promise.reject(
                    "End date must be greater than start date"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            name="endDate"
            value={tempEndDate}
            onChange={(date, dateString) => {
              setEndDate(moment(dateString))
              setTempEndDate(date)}
            }
            disabled={tempStartDate ? false : true}
            disabledDate={(d) => !d || d.isAfter(future) || d.isBefore(tempStartDate)}
            onKeyDown={(e) => {
              e.preventDefault()
              return false
            }}
          />
        </Form.Item>

        <Form.Item label="Chains">
          <Select
            allowClear
            mode="multiple"
            options={chains}
          //  value={form.chain_id}
          //  onChange={(e) => updateForm("chain_id", e)}
          />
        </Form.Item>

        <Form.Item label="Region" name="hierarchy_id">
          <Cascader
            multiple
            placeholder="Please select new regions"
            options={treeData}
            expandTrigger="hover"
            displayRender={displayRender}
            onChange={(val, e) => {
              // setForm({
              //   ...form,
              //   hierarchy_id: val,
              // });
              // onChange("hierarchy_id", val);
            }}
            //  showSearch={{ filter }}
            changeOnSelect
          />
        </Form.Item>

        <Space style={{ width: "100%" }} direction="vertical">
          <Form.Item label="Reports:">
            <Select
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={handleChange}
              options={options}
            />
          </Form.Item>
        </Space>
        <Form.Item label="Notice:">
          <div
            style={{
              fontWeight: "bold",
            }}
          >
            Not seeing the report you need? Please check again later, it's being
            synced to your account.
          </div>
        </Form.Item>
        <Divider />
        {/* <Form.Item label="Action">
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => generateReports("CSV")}
            disabled={!isSubmitEnabled}
            loading={isLoading}
          >
            Generate reports
            <FileExcelTwoTone />
          </Button>
        </Form.Item> */}
        <Form.Item label="Action">
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => generateReports("XLSX")}
            disabled={!isSubmitEnabled}
            loading={isLoading}
          >
            Generate reports
            <FileExcelTwoTone />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ReportsPage;