import { useEffect, useState } from "react";
import mockImg from "../../assets/rc-logo-512.png";
import { Form } from "antd";
import * as S from "./ResetPassword.styles";
import AndtInput from "../Andt/input/AndtInput";
import AndtButton, { ANDT_BUTTON_TYPES } from "../Andt/Button/AndtButton";
import useApiPost from "../../hooks/useApiPost";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { HASH_SECRET_PASS } from "../../config/cryptoEncryption";
enum BUTTON_TYPES {
  password = "PASSWORD",
  text = "TEXT",
}
interface ValidationMessageProps {
  isValid: boolean;
  message: string;
}
interface ValidationStatus {
  length: boolean;
  noUsernameMatch: boolean;
  noSuccessive: boolean;
  confirmPasswordMatch: boolean;
  hasNumbersAndLetters: boolean; // New validation flag
  noWhitespace: boolean; // New validation flag for whitespace
}

const ResetPassword = () => {
  const [UserName, setUserName] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [PassValid, setPassValid] = useState(false);
  const { noAuthRequest } = useApiPost();
  const [buttonType, setButtonType] = useState(BUTTON_TYPES.password);
  const navigate = useNavigate();
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token") || "";
    const userName = params.get("uName") || "";
    setUserName(userName);
    setToken(token);
  }, []);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>({
    length: false,
    noUsernameMatch: false,
    noSuccessive: false,
    confirmPasswordMatch: false,
    hasNumbersAndLetters: false, // Initialize new validation flag
    noWhitespace: false  // Initialize the new validation flag
  });

  useEffect(() => {
    if(validationStatus.length && 
      validationStatus.noUsernameMatch && 
      validationStatus.noSuccessive &&
      validationStatus.confirmPasswordMatch &&
      validationStatus.hasNumbersAndLetters &&
      validationStatus.noWhitespace
      ) {
        setPassValid(true)
      } else {
        setPassValid(false);
      }
  }, [validationStatus])

  const validatePassword = (input: string) => {
    setValidationStatus({
      length: input.length >= 8 && input.length > 0,
      noUsernameMatch: !isIdenticalUserName(input) && input.length > 0,
      noSuccessive: !/(\w)\1\1/.test(input) && input.length > 0,
      hasNumbersAndLetters:
        /[a-zA-Z]/.test(input) && /\d/.test(input) && input.length > 0, // Check for both letters and digits
      noWhitespace: !/\s/.test(input) && input.length > 0, // Check that there are no whitespace characters,
      confirmPasswordMatch:
        (input == password || input == confirmPassword) && input.length > 0,
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPass = event.target.value;
    setPassword(newPass);
    validatePassword(newPass);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPass = event.target.value;
    setConfirmPassword(newConfirmPass);
    validatePassword(newConfirmPass);
  };
  
  const handleSwitchButtonType = () => {
    if (buttonType === BUTTON_TYPES.password) {
      return setButtonType(BUTTON_TYPES.text);
    } else {
      return setButtonType(BUTTON_TYPES.password);
    }
  };

  const handleAction = async () => {
      const encryptedPassword = CryptoJS.AES.encrypt(
        password,
        HASH_SECRET_PASS
      ).toString();
      if (encryptedPassword) {
        const data = { token, newPassword: encryptedPassword };
        const authResponse = await noAuthRequest(
          "/office/forgotPass/reset-password",
          "POST",
          data
        );
        if (authResponse?.status === 200) {
          goToLoginScreen();
          toast.info(
            `${authResponse.message} \nyou will redirected to login page`,
            {
              position: "bottom-center",
            }
          );
        } else {
          toast.info(authResponse.message, {
            position: "bottom-center",
          });
        }
      }
  };

  const goToLoginScreen = () => {
    navigate("/login");
  };

  function isIdenticalUserName(newPassword: string) {
    const parts = UserName.split(" ");
    const firstName = parts[0];
    const lastName = parts[parts.length - 1];

    // Convert names and password to lowercase for case-insensitive comparison
    const lowerFirstName = firstName.toLowerCase();
    const lowerLastName = lastName.toLowerCase();
    const lowerPassword = newPassword.toLowerCase();
    // Check if the password contains the first or last name
    return (
      lowerPassword.includes(lowerFirstName) ||
      lowerPassword.includes(lowerLastName)
    );
  }

  const ValidationMessage: React.FC<ValidationMessageProps> = ({
    isValid,
    message,
  }) => {
    const color = isValid ? "green" : "red";
    return (
      <S.FormWrapper style={{ color, width: "500px" }}>
        • {message}
      </S.FormWrapper>
    );
  };

  return (
    <S.Wrapper>
      <S.ImageWrapper>
        <S.StyledImage src={mockImg} />
      </S.ImageWrapper>
      <S.TitleWrapper>
        <div
          style={{
            paddingBottom: "10px",
          }}
        >
          <S.Title>{"Reset Password"}</S.Title>
        </div>
      </S.TitleWrapper>

      <S.InputsWrapper>
        <AndtInput
          label={"Enter new password"}
          type={buttonType}
          placeholder={"Password"}
          onChange={handlePasswordChange}
        />

        <S.InputWithButtonWrapper>
          <AndtInput
            label={"Confirm password"}
            type={buttonType}
            placeholder={"Confirm password"}
            onChange={handleConfirmPasswordChange}
          />
          <S.ShowButton onClick={() => handleSwitchButtonType()}>
            show
          </S.ShowButton>
        </S.InputWithButtonWrapper>
        <div>
          <ValidationMessage
            isValid={validationStatus.length}
            message="Password must be at least 8 characters long."
          />
          <ValidationMessage
            isValid={validationStatus.noUsernameMatch}
            message="Password cannot be the same as the username."
          />
          <ValidationMessage
            isValid={validationStatus.noSuccessive}
            message="Password cannot contain more than three successive identical characters."
          />
          <ValidationMessage
            isValid={validationStatus.hasNumbersAndLetters}
            message="Password must contain both numerical and alphabetical characters."
          />
          <ValidationMessage
            isValid={validationStatus.noWhitespace}
            message="Password must not contain whitespace."
          />
          <ValidationMessage
            isValid={validationStatus.confirmPasswordMatch}
            message="Passwords must match."
          />
        </div>
        <S.FormWrapper style={{ paddingTop: "10px" }}>
          <Form method="post">
            <AndtButton
              type={ANDT_BUTTON_TYPES.primary}
              isDisabled={!PassValid}
              onClick={handleAction}
              text={"Submit"}
            />
          </Form>
        </S.FormWrapper>
        <div
          style={{
            paddingTop: "10px",
          }}
        >
          <div onClick={goToLoginScreen}>{"Go to Login"}</div>
        </div>
      </S.InputsWrapper>
    </S.Wrapper>
  );
};
export default ResetPassword;
