import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Typography, Select, Switch } from "antd";
import { EMPTY_USER } from "../../../../utils/user";
import { AppContext } from "../../../../App";
import { DataType, IOption, Delegate } from "../types";
import { DefaultOptionType } from "antd/lib/select";
import { useNavigate, useParams } from "react-router-dom";
import useApiPost from "../../../../hooks/useApiPost";

const DelegateForm = () => {
  const { delegateId } = useParams<{ delegateId: string }>();
  const { projectId } = useContext(AppContext);
  const [form] = Form.useForm();
  const { request, setError } = useApiPost();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [delegateRolesOptions, setDelegateRolesOptions] = useState<IOption[]>(
    []
  );
  const [genderOptions, setGenderOptions] = useState<IOption[]>([]);
  const [ethnicityOptions, setEthnicityOptions] = useState<IOption[]>([]);
  const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
  const [outletsOptions, setOutletsOptions] = useState<IOption[]>([]);
  const [delegateNetworkOptions, setdelegateNetworkOptions] = useState<
    IOption[]
  >([]);
  const [treeData, setTreeData] = useState<DataType[]>([]);
  const navigate = useNavigate();
  const headerText = delegateId ? "Edit Delegate" : "Add Delegate";

  const [formOptionValues, setFormOptionValues] = useState({
    hierarchy_id: null,
    network_id: null,
    role_id: null,
    customer_id: null,
  });

  const [initValues, setInitValues] = useState<Delegate>(EMPTY_USER);

  const fetchDelegateData = async () => {
    setIsLoading(true);
    if (!delegateId) {
      return;
    }

    const delegateData = await request(
      `/office/delegates/${delegateId}`,
      "POST",
      {
        projectId,
      }
    );

    if (!delegateData) {
      navigate(`/admin`);
      return;
    }
    const { hierarchy_id, network_id, role_id, customer_id, language_id } =
      delegateData;
    setFormOptionValues({
      hierarchy_id,
      network_id,
      role_id,
      customer_id,
    });

    setInitValues({
      ...delegateData,
      updatedAt: delegateData.updated_at
        ? new Date(delegateData.updated_at).toLocaleDateString()
        : "",
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDelegateData();
  }, [delegateId, projectId]);

  useEffect(() => {
    form.setFieldsValue(initValues);
  }, [initValues, delegateId]);

  const handleComeBack = () => navigate("/admin/delegates");

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const {
        networks,
        roles,
        ethnicityOptions,
        genderOptions,
        languages,
        outlets,
      } = await request("/office/delegates/options/creation/delegate", "POST", {
        project_id: projectId,
      });
      setGenderOptions(genderOptions);
      setLanguageOptions(languages);
      setEthnicityOptions(ethnicityOptions);
      setDelegateRolesOptions(roles);
      setdelegateNetworkOptions(networks);
      setOutletsOptions(outlets);
      setIsLoading(false);
    };
    fetchAllData();
  }, [projectId]);

  const handleInsert = async (values: any) => {
    try {
      const { message } = await request("/office/delegates/insert", "POST", {
        ...values,
      });

      if (message === "Delegate already exists") {
        setIsLoading(false);
        return alert("Delegate with same ID Number already exists.");
      }
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    try {
      await request("/office/delegates/update", "POST", {
        ...values,
        delegateId,
      });
      setIsLoading(false);
      handleComeBack();
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const submitData = {
      ...values,
      ...formOptionValues,
      project_id: projectId,
    };

    if (delegateId) {
      handleUpdate({
        ...submitData,
        delegateId,
      });
    } else {
      handleInsert(submitData);
    }
  };

  const handleOptionChange = (key: string, event: any) => {
    setFormOptionValues({
      ...formOptionValues,
      [key]: event.value,
    });
  };

  if (isLoading || genderOptions.length === 0) {
    return <p>Loading...</p>;
  }

  const displayRender = (labels: string[]) => {
    const label = labels[labels.length - 1];
    const labelText = getLabel(label);

    return labelText ? labelText : label;
  };
  const onChange = (key: string, value: any) => {
    const lastValue = Array.isArray(value) ? value[value.length - 1] : value;
    handleOptionChange(key, { value: lastValue });
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  const getLabel = (id: string) => {
    let label = "";
    const findLabel = (data: DataType[]) => {
      data.forEach((item) => {
        if (item.id === id) {
          label = item.title;
        } else if (item.children && item.children.length > 0) {
          findLabel(item.children);
        }
      });
    };
    findLabel(treeData);
    return label;
  };

  return (
    <>
      <Typography.Title>{headerText}</Typography.Title>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        // wrapperCol={{ span: 10 }}
        onFinish={onFinish}
        initialValues={initValues}
      >
        <Form.Item label="Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Surname" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          // rules={[
          //   {
          //     type: "email",
          //     message: "The input is not valid E-mail!",
          //   },
          //   {
          //     required: true,
          //     message: "Please input your E-mail!",
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Phone Number" name="mobileNumber">
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          hasFeedback
          name="role_id"
          rules={[{ required: true, message: "Role is required to proceed!" }]}
          required
        >
          <Select
            loading={isLoading}
            options={delegateRolesOptions}
            defaultValue={formOptionValues.role_id}
            onChange={(_value, e) => {
              handleOptionChange("role_id", e);
            }}
          />
        </Form.Item>
        <Form.Item
          label="ID Number"
          name="idNumber"
          rules={[
            {
              required: true,
              message: "ID Number is mandatory",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Gender" name="gender">
          <Select loading={isLoading} options={genderOptions} />
        </Form.Item>
        <Form.Item label="Ethnicity" name="ethnicity_id">
          <Select loading={isLoading} options={ethnicityOptions} />
        </Form.Item>
        <Form.Item label="Language" name="language_id">
          <Select loading={isLoading} options={languageOptions} />
        </Form.Item>
        <Form.Item label="Last Change Date" name="updatedAt">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Network">
          <Select
            loading={isLoading}
            options={delegateNetworkOptions}
            defaultValue={formOptionValues.network_id}
            onChange={(_value, e) => {
              handleOptionChange("network_id", e);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Outlet"
          hasFeedback
          name="customer_id"
          required
          rules={[
            { required: true, message: "Outlet is required to proceed!" },
          ]}
        >
          <Select
            loading={isLoading}
            options={outletsOptions}
            defaultValue={formOptionValues.customer_id}
            onChange={(_value, e) => {
              handleOptionChange("customer_id", e);
            }}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            showSearch={true}
          />
        </Form.Item>
        <Form.Item
          label="Is Disabled"
          valuePropName="isDisabled"
          name="isDisabled"
        >
          <Switch defaultChecked={initValues.isDisabled} />
        </Form.Item>
        <Form.Item
          label="Is C3D User?"
          valuePropName="isC3DUser"
          name="isC3DUser"
        >
          <Switch defaultChecked={initValues.isC3DUser} />
        </Form.Item>
        <Form.Item label="Is M2 User?" valuePropName="isM2User" name="isM2User">
          <Switch defaultChecked={initValues.isM2User} />
        </Form.Item>
        <Form.Item
          label="Is Axonify User?"
          valuePropName="isAxonifyUser"
          name="isAxonifyUser"
        >
          <Switch defaultChecked={initValues.isAxonifyUser} />
        </Form.Item>
        <Form.Item label="Is active?" valuePropName="isActive" name="isActive">
          <Switch defaultChecked={initValues.isActive} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DelegateForm;
